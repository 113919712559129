import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function Navbar({ setType, setAboutType, navigation }) {
	return (
		<div>
			<Popover>
				<div className="relative py-6 px-4 sm:px-6 lg:px-8">
					<nav className="relative flex items-center justify-between sm:h-20" aria-label="Global">
						<div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
							<div className="flex items-center justify-between w-full md:w-auto">
								<a href="/">
									<span className="sr-only">Дем’янович</span>
									<img
										className="w-auto h-32 md:h-40"
										src="../../logo.png"
										alt="Дем’янович"
									/>
								</a>
								<div className="-mr-2 flex items-center md:hidden">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400">
										<span className="sr-only">Open main menu</span>
										<MenuIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
						</div>

						<div className="hidden md:flex md:flex-row md:pr-4 md:space-x-6">
							{navigation.map((item) => (
								!item.children ? (<a key={item.name} href={item.href} className="lg:text-xl font-light text-white hover:text-gray-900 hover:bg-white rounded-lg px-2 py-1.5">
									{item.name}
								</a>) : (
									<Popover key={item.name} className="relative">
										{({ open }) => (
											<>
												<Popover.Button
													className={classNames(
														open ? 'text-gray-900 focus:text-gray-900 focus:bg-white' : 'text-white',
														'hover:text-gray-900 hover:bg-white group rounded-lg px-2 py-1.5 inline-flex items-center text-base font-medium '
													)}
												>
													<span className="lg:text-xl font-light">{item.name}</span>
													<ChevronDownIcon
														className={classNames(
															open ? 'text-gray-900' : 'text-white group-hover:text-gray-900',
															'h-5 w-5'
														)}
														aria-hidden="true"
													/>
												</Popover.Button>

												<Transition
													as={Fragment}
													enter="transition ease-out duration-200"
													enterFrom="opacity-0 translate-y-1"
													enterTo="opacity-100 translate-y-0"
													leave="transition ease-in duration-150"
													leaveFrom="opacity-100 translate-y-0"
													leaveTo="opacity-0 translate-y-1"
												>
													<Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
														<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
															<div className="relative grid gap-2 bg-green-400 sm:gap-2 sm:p-4">
																{item.children.map((itemChild) => (
																	<a
																		key={itemChild.name}
																		href={item.name === 'Продукція' ? '#products' : '#aboutus'}
																		className="mx-auto p-1 flex items-start rounded-lg text-xl font-light text-white hover:bg-gray-50 hover:text-gray-900"
																		onClick={() => item.name === 'Продукція' ? setType(itemChild.name) : setAboutType(itemChild.name)}
																	>
																		{itemChild.name}
																	</a>
																))}
															</div>
														</div>
													</Popover.Panel>
												</Transition>
											</>
										)}
									</Popover>)
							))}
						</div>
					</nav>
				</div>

				<Transition
					as={Fragment}
					enter="duration-150 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						focus
						className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
					>
						<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
							<div className="px-5 pt-4 flex justify-end">
								<div className="-mr-2">
									<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400">
										<span className="sr-only">Close main menu</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</Popover.Button>
								</div>
							</div>
							<div className="px-2 pt-2 pb-3 space-y-1">
								{navigation.map((item) => (
									<a
										key={item.name}
										href={item.href}
										className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
									>
										{item.name}
									</a>
								))}
							</div>
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	)
}
