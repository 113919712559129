import React from 'react'
import { PhoneIcon } from '@heroicons/react/solid'
import { ArrowNarrowUpIcon } from '@heroicons/react/outline'

export default function Footer() {
    return (
        <div id="contacts" className="mt-12 lg:mt-24">
            <footer className="text-white">
                <div className="py-16 flex flex-col sm:place-items-start md:place-items-center md:flex-row font-light justify-between">
                    <p className="text-center sm:text-left md:text-left">
                        Всі права захищені©. Власник сайту ФОП Червєнков Г.Д.
                    </p>

                    <div className="flex flex-col sm:flex-row items-center sm:justify-between md:justify-end">
                        <div className="sm:text-left text-center md:w-60">
                            <div className="my-5">
                                <p className="text-xl md:ml-9 mb-2">Контакти:</p>
                                <nav className="list-none ">
                                    <li className="flex flex-rows">
                                        <PhoneIcon className="w-6 h-6 mr-3" />
                                        <a href="tel:+38050-315-65-75" className="text-lg hover:text-gray-100">+38 (050) 315 65 75</a>
                                    </li>
                                    <li className="flex flex-rows">
                                        <PhoneIcon className="w-6 h-6 mr-3" />
                                        <a href="tel:+38098-315-77-99" className="text-lg hover:text-gray-100">+38 (098) 315 77 99</a>
                                    </li>
                                    <li className="flex flex-rows">
                                        <PhoneIcon className="w-6 h-6 mr-3" />
                                        <a href="tel:+38050-494-11-74" className="text-lg hover:text-gray-100">+38 (050) 494 11 74</a>
                                    </li>
                                </nav>
                                <p className="mt-4"><a href="mailto:demjanovich66@ukr.net">demjanovich66@ukr.net </a></p>
                            </div>
                        </div>
                        <div>
                            <a href="#home">
                                <ArrowNarrowUpIcon className="w-16 h-16" />
                            </a>

                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
